import $ from "@modules/jquery";
import "./ads";

$(function () {
  let titleHeight = 0;
  let detailHeight = 0;
  let priceHeight = 0;
  let orderHeight = 0;
  const title = $(".title-container");
  const detail = $(".details");
  const price = $(".price-container");
  const order = $(".order-container");

  title.each(function () {
    titleHeight =
      $(this).height() > titleHeight ? $(this).height() : titleHeight;
  });

  detail.each(function () {
    detailHeight =
      $(this).height() > detailHeight ? $(this).height() : detailHeight;
  });

  price.each(function () {
    priceHeight =
      $(this).height() > priceHeight ? $(this).height() : priceHeight;
  });

  order.each(function () {
    orderHeight =
      $(this).height() > orderHeight ? $(this).height() : orderHeight;
  });

  //Assign maximum height to children
  title.height(titleHeight);
  detail.height(detailHeight);
  price.height(priceHeight);
  order.height(orderHeight);

  //Assign the largest height to the parent only
  $(".title-container").height(titleHeight);
  $(".details").height(detailHeight);
  $(".price-container").height(priceHeight);
  $(".order-container").height(orderHeight);
});

$(function () {
  $("#gform_1 .gform_body").hide();
  $("#gform_1 .gform_footer").hide();
  $("#fTog").click(function (e) {
    e.preventDefault();
    $(this).html(
      $(this).html() == "Deel je verhaal" ? "Sluiten" : "Deel je verhaal"
    );
    $(".gform_body").slideToggle("slow");
    $(".gform_footer").slideToggle("slow");
  });
});
